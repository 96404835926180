<template>
	<el-select v-model="taskNames" multiple collapse-tags @change="changeValue" size="mini" placeholder="选择任务类型">
		<!-- 建账采集 -->
		<el-option v-for="item in (jcType == '1' ? JzCjOptions : jcType == '2' ? CjOptions : jcType == '3' ? SbOptions : jcType == '4' ? jcOptions :jcType == '5' ? yearOption : options)"
			:key="item.value" :label="item.label" :value="item.value" />
	</el-select>
	<!-- <el-checkbox-group size="mini" v-model="taskNames">
		<el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" @change="changeValue">{{item.label}}</el-checkbox-button>
	</el-checkbox-group> -->

</template>

<script>
export default {
	name: 'selectTaskCamera',
	props: {
		taskNames: {
			type: Array,
      default: [],
		},
		jcType: ''
	},
	data() {
		return {
			// 建账采集
			JzCjOptions: [
				{
					value: 'tax-cj-jianzhang'
					, label: "采集-建账采集(企业信息和税种税表)"
				},
				{
					value: 'other-cj-jianzhang'
					, label: "历史数据-建账采集"
				},
				{
					value: 'tax-cj-taxCategory'
					, label: "采集-更新税种"
				},
				{
					value: 'tax-ybjc'
					, label: "采集-一表集成"
				},
				{
					value: 'tax-cj-resetTaxs'
					, label: "采集-重新采集税表"
				},
			],

			// 采集
			CjOptions: [
				{
					value: 'tax-cj-outInvoice'
					, label: "采集-销项发票"
				},
				{
					value: 'tax-cj-inInvoice'
					, label: "采集-进项发票"
				},
			],

			// 税局申报
			SbOptions: [
				{
					value: 'tax-sb-smallVat'
					, label: "申报-小规模增值税纳税人"
				},
				{
					value: 'tax-sb-vat'
					, label: "申报-增值税一般纳税人"
				},
				{
					value: 'tax-sb-taxQuarter'
					, label: "申报-企业所得税申报表"
				},
				{
					value: 'tax-sb-deed'
					, label: "申报-行为税"
				},
				{
					value: 'tax-sb-qtsr'
					, label: "申报-其他收入"
				},
				{
					value: 'tax-sb-sl'
					, label: "申报-水利建设专项收入"
				},
				{
					value: 'tax-sb-cbj'
					, label: "申报-文化事业建设费"
				},
				{
					value: 'tax-sb-cbj'
					, label: "申报-残疾人保障金"
				},
				{
					value: 'tax-sb-xfs'
					, label: "申报-消费税"
				},
				{
					value: 'tax-sb-ljcl'
					, label: "申报-城市生活垃圾处置费"
				},
				{
					value: 'tax-sb-shebao'
					, label: "申报-社保申报"
				},
				{
					value: 'tax-sb-cwbb'
					, label: "申报-财务报表"
				}
			],

			// 全部的
			options: [
				{
					value: 'tax-sb-smallVat'
					, label: "申报-小规模增值税纳税人"
				},
				{
					value: 'tax-sb-vat'
					, label: "申报-增值税一般纳税人"
				},
				{
					value: 'tax-sb-taxQuarter'
					, label: "申报-企业所得税申报表"
				},
				{
					value: 'tax-sb-deed'
					, label: "申报-行为税"
				},
				{
					value: 'tax-sb-qtsr'
					, label: "申报-其他收入"
				},
				{
					value: 'tax-sb-sl'
					, label: "申报-水利建设专项收入"
				},
				{
					value: 'tax-sb-whsyjsf'
					, label: "申报-文化事业建设费"
				},
				{
					value: 'tax-sb-cbj'
					, label: "申报-残疾人保障金"
				},
				{
					value: 'tax-sb-xfs'
					, label: "申报-消费税"
				},
				{
					value: 'tax-sb-ljcl'
					, label: "申报-城市生活垃圾处置费"
				},
				{
					value: 'tax-sb-shebao'
					, label: "申报-社保申报"
				},
				{
					value: 'tax-sb-cwbb'
					, label: "申报-财务报表"
				},

				{
					value: 'tax-cj-jianzhang'
					, label: "采集-建账采集(企业信息和税种税表)"
				},
				{
					value: 'tax-cj-jump'
					, label: "采集-跳过采集"
				},
				{
					value: 'tax-cj-taxCategory'
					, label: "采集-更新税种"
				},
				{
					value: 'tax-ybjc'
					, label: "采集-一表集成"
				},
				{
					value: 'tax-cj-resetTaxs'
					, label: "采集-重新采集税表"
				},
				{
					value: 'tax-cj-outInvoice'
					, label: "采集-销项发票"
				},
				{
					value: 'tax-cj-inInvoice'
					, label: "采集-进项发票"
				},
				{
					value: 'tax-cj-invoice'
					, label: "采集-进项销项发票"
				},
				{
					value: 'tax-cj-shebao'
					, label: "采集-社保采集"
				},

				{
					value: 'tax-kk-smallVat'
					, label: "扣款-小规模增值税纳税人"
				},
				{
					value: 'tax-kk-vat'
					, label: "扣款-增值税一般纳税人"
				},
				{
					value: 'tax-kk-taxQuarter'
					, label: "扣款-企业所得税申报表"
				},
				{
					value: 'tax-kk-deed'
					, label: "扣款-行为税"
				},
				{
					value: 'tax-kk-qtsr'
					, label: "扣款-其他收入"
				},
				{
					value: 'tax-kk-sl'
					, label: "扣款-水利建设专项收入"
				},
				{
					value: 'tax-kk-whsyjsf'
					, label: "扣款-文化事业建设费"
				},
				{
					value: 'tax-kk-cbj'
					, label: "扣款-残疾人保障金"
				},
				{
					value: 'tax-kk-xfs'
					, label: "扣款-消费税"
				},
				{
					value: 'tax-kk-ljcl'
					, label: "扣款-城市生活垃圾处置费"
				},
				{
					value: 'tax-kk-fjs'
					, label: "扣款-附加税"
				},
				{
					value: 'tax-kk-shebao'
					, label: "扣款-社保申报"
				},
				{
					value: 'tax-kk-cwbb'
					, label: "扣款-财务报表"
				},

				{
					value: 'tax-jc-shenbao'
					, label: "检查-检查"
				},
				{
					value: 'tax-jc-shebao'
					, label: "检查-社保"
				},

				{
					value: 'his-cj-historyCollect'
					, label: "采集-历史数据采集"
				},
				{
					value: 'other-cj-jianzhang'
					, label: "建账采集"
				},
			],

			// 检查
			jcOptions: [
				{
					value: 'tax-jc-shenbao'
					, label: "检查-检查"
				},
				{
					value: 'tax-jc-shebao'
					, label: "检查-社保"
				},
			],
			yearOption:[
			{
					value: 'tax-cwyear-sb',
					label: '申报-财务报表',
				},
        {
					value: 'tax-year-sb',
          label: "申报-汇算清缴"
				},
				{
					value: 'tax-year-cj',
					label: '采集-汇算清缴',
				}
			]

		};
	},
	mounted() {

	},
	methods: {
		changeValue() {
			// if (jcType == '1') {
			// 	this.taskNames = ["other-cj-jianzhang"]
			// } else if (jcType == '2') {
			// 	this.taskNames = ["tax-cj-outInvoice", 'tax-cj-inInvoice']
			// } else if (jcType == '3') {
			// 	this.taskNames = ['tax-sb-smallVat', 'tax-sb-vat', 'tax-sb-taxQuarter', 'tax-sb-deed', 'tax-sb-qtsr', 'tax-sb-sl', 'tax-sb-cbj', 'tax-sb-cbj', 'tax-sb-xfs', 'tax-sb-ljcl', "tax-sb-shebao", 'tax-sb-cwbb']
			// }
			this.$emit('update:taskNames', this.taskNames)
			this.$emit("success", this.taskNames)
		},
	},
};
</script>

<style lang="scss" scoped>

</style>
<template>
  <el-select v-model="address" class="m-2" placeholder="选择地区" size="mini" @change="changeValue" clearable filterable>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.address"
      :value="item.code"
    />
</el-select>
</template>

<script>
import { queueTaskAddress } from "@/api/ea";

export default {
  name: 'selectAddress',
  props: {
    address: {
      type: String,
      default: ""
    },
  },
  created(){
    this.init()
  },
  data() {
    return {
      options:[]
    };
  },
  mounted() {
    
  },

  methods: {
    init(){
      queueTaskAddress({}).then(res => {
        if(res.data.msg == "success"){
          this.options = res.data.data.list
        }
      })
    },
    changeValue(){
      this.$emit('update:address', this.address)
      this.$emit('success',this.address)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
import request from '@/utils/request'

//企业列表
export function companyTaxList(data) {
  return request({
    url: 'api/v1/companyTaxList',
    method: 'post',
    data
  })
}

//任务监控列表
export function taxTaskList(data) {
    return request({
      url: 'api/v1/taxTaskList',
      method: 'post',
      data
    })
  }

  //待执行列表
export function queueList(data) {
    return request({
      url: 'api/v1/queueList',
      method: 'post',
      data
    })
  }

  //已执行列表
export function queueRecordList(data) {
    return request({
      url: 'api/v1/queueRecordList',
      method: 'post',
      data
    })
  }

    //任务执行情况
export function getQueueStatus(data) {
  return request({
    url: 'api/v1/getQueueStatus',
    method: 'post',
    data
  })
}

//清除队列
export function clearQueue(data) {
  return request({
    url: 'api/v1/clearQueue',
    method: 'post',
    data
  })
}

//清除手机号锁
export function clearTelLock(data) {
  return request({
    url: 'api/v1/clearTelLock',
    method: 'post',
    data
  })
}

//再次回调
export function againTaxCallback(data) {
  return request({
    url: 'api/v1/againTaxCallback',
    method: 'post',
    data
  })
}

//任务分发规则
export function queueRuleList(data) {
  return request({
    url: 'api/v1/queueRuleList',
    method: 'post',
    data
  })
}

//
export function queueRuleSave(data) {
  return request({
    url: 'api/v1/queueRuleSave',
    method: 'post',
    data
  })
}

//
export function queueRuleDel(data) {
  return request({
    url: 'api/v1/queueRuleDel',
    method: 'post',
    data
  })
}

export function queueEditRule(data) {
  return request({
    url: 'api/v1/queueEditRule',
    method: 'post',
    data
  })
}

export function readFile(data) {
  return request({
    url: 'api/v1/readFile',
    method: 'post',
    data
  })
}

export function endQueueByHand(data) {
  return request({
    url: 'api/v1/endQueueByHand',
    method: 'post',
    data
  })
}

//重新发起
export function taxTaskAgain(data) {
  return request({
    url: 'api/v1/taxTaskAgain',
    method: 'post',
    data
  })
}

//查看详情
export function getTaskDetail(data) {
  return request({
    url: 'api/v1/getTaskDetail',
    method: 'post',
    data
  })
}


//任务监控列表
export function getTaxList(data) {
  return request({
    url: 'api/v1/jk/getTaxList',
    method: 'post',
    data
  })
}

//任务监控列表
export function getCompanyList(data) {
  return request({
    url: 'api/v1/jk/getCompanyList',
    method: 'post',
    data
  })
}

//任务监控列表
export function getStatistics(data) {
  return request({
    url: 'api/v1/jk/getStatistics',
    method: 'post',
    data
  })
}

//按地区统计任务监控列表
export function getStatisticsAddress(data) {
  return request({
    url: 'api/v1/jk/getStatisticsAddress',
    method: 'post',
    data
  })
}
